import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import ProductList from "../components/product-list"
import ReactSwipe from "react-swipe"
import "../style.css"

const windowGlobal = typeof window !== 'undefined' && window;

class Fancy extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			idx: 0,
			interval: null
		}
	}
	componentDidMount() {
		const interval = setInterval(this.next, 2500);
		this.setState({
			interval: interval
		})
	}
	componentWillUnmount() {
		clearInterval(this.state.interval);
	}
	next = () => {
		let idx = this.state.idx;
		idx = idx + 1;
		if(idx >= this.props.words.length) {
			clearInterval(this.state.interval);
		}
		else {
			this.setState({
				idx: idx
			})
		}
	}
	render() {
		const word = this.props.words[this.state.idx];
		let c = this.state.idx === 0 ? 'fade-out' : '';
		c = this.state.idx < (this.props.words.length - 1) ? 'fade-in-out' : c;
		return (
			<span key={ word } className={ c }>{ word }.</span>
		)
	}
}

class App extends React.Component {
	render() {
		const categories = this.props.data.categories.edges.map(({node}) => node);
		const words = ["wedding", "engagement", "birthday", "anniversary", "reunion", "moment"];
		return (
			<Layout>
				<div className='ph3 ph4-ns relative'>
					<div className='tc pt4 pt5-ns pb3-ns pb5-m pb5-l pb5-xl tc'>
						<img className='center hero-500-ns' src="/assets/hero_35r.png" />
					</div>
					<div className='mt4 mt0-ns'>
						<div className='cf relative z-2'>
							<div className='w-100 w-60-m w-60-l w-50-xl center pv4'>
								<div className='tc'>
									<p className='ma0 pa0 pb2 f2 f1-ns arvo black-80 lh-copy fw4'>Your <Fancy words={ words } /><br/><span className='black-90'>Your way.</span></p>
										<p className='lh-copy mt4 black-80 ph3 ph0-ns f6 f4-ns'><span className='arvo black'>610 Design Studio</span> is a laser cutting studio that specializes in event decor and custom design work. We have one goal: to help your special day—whatever the occasion—become a memory that lasts forever. Our lasercut offerings are custom-made for your celebration, and we hope that they'll serve as a reminder of that special day for years to come.</p>
								</div>
							</div>
						</div>
						<div className='mv4 mv5-ns cf nh4 center relative z-2'>
							<div className='w-100 w-50-ns mb4 mb0-ns fl pv3 pv4-ns ph4 tc pl6-ns'>
								<Link to="/custom-work" className='db dim pointer no-underline bg-black-90 pa3 ph4 tc mb4'>
									<p className='ttu tracked f6 white'>Custom Work</p>
								</Link>
								<p className='f6 i black-50 ma0 pa0 ph3 lh-copy'>Let's work side-by-side to develop the perfect look and feel for your perfect event.</p>
							</div>
							<div className='w-100 w-50-ns mb4 mb0-ns fl pv3 pv4-ns ph4 tc pr6-ns'>
								<Link to="/shop" className='db dim pointer no-underline bg-black-05 pa3 ph4 tc mb4'>
									<p className='ttu tracked f6 black-90'>Ready-Made</p>
								</Link>
								<p className='f6 i black-50 ma0 pa0 ph3 lh-copy'>Browse our selection of ready-made designs.</p>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default App;

export const query = graphql`
	query Home {
		categories: allAirtableCategories {
			totalCount
			edges {
				node {
					name
					slug
				}
	  		}
		}
  }
`